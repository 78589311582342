<template>
  <div class="app-container">
    <!-- form表单 -->
    <el-form ref="form" :model="form" inline size="small">
      <!-- 平台 -->
      <el-form-item :label="$t('title.platform')">
        <el-select v-model="form.platform" :placeholder="$t('page.selectPlaceholder')" clearable collapse-tags filterable @change="platformChange">
          <el-option :label="$t('title.All')" value="">{{ $t('title.All') }}</el-option>
          <el-option
            v-for="item in platformList"
            :key="item.id"
            :label="item.platformCode"
            :value="item.platformCode"
          />
        </el-select>
      </el-form-item>
      <!--平台账号-->
      <el-form-item :label="$t('title.SellingAccount')">
        <el-select v-model="form.account" clearable collapse-tags filterable @change="PlatformAccountChange">
          <el-option :label="$t('title.All')" value="">{{ $t('title.All') }}</el-option>
          <el-option
            v-for="item in PlatformAccountList"
            :key="item.id"
            :label="item.account"
            :value="item.account"
          />
        </el-select>
      </el-form-item>
      <!--平台站点-->
      <el-form-item :label="$t('title.platforms')">
        <el-select v-model="form.site" clearable filterable>
          <el-option :label="$t('title.All')" value="">{{ $t('title.All') }}</el-option>
          <el-option
            v-for="item in terraceData"
            :key="item.id"
            :label="item.platformSite"
            :value="item.platform==='AMAZON' ? item.site : item.platformSite"
          />
        </el-select>
      </el-form-item>
      <!-- BU -->
      <el-form-item label="BU">
        <el-select v-model="form.bu" clearable collapse-tags filterable multiple value-key="id">
          <el-option
            v-for="item in buOptions"
            :key="item.id"
            :label="item.bu"
            :value="item.bu"
          />
        </el-select>
      </el-form-item>
      <!-- 客户id -->
      <el-form-item :label="$t('title.customerID')">
        <el-input
          v-model="form.messageSender"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          @keyup.enter.native="_getAmazonPagedList(0)"
        />
      </el-form-item>
      <!-- 工单状态 -->
      <el-form-item :label="$t('title.TicketStatus')">
        <el-select v-model="form.workStatus" clearable filterable @change="workStatusChange">
          <el-option :label="$t('title.Unfinished')" value="0" />
          <el-option :label="$t('title.finished')" value="99" />
        </el-select>
      </el-form-item>
      <!-- 平台订单号 -->
      <el-form-item :label="$t('title.platNumber')">
        <el-input
          v-model="form.orderCoder"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          @keyup.enter.native="_getAmazonPagedList(0)"
        />
      </el-form-item>
      <!-- 工单号 -->
      <el-form-item :label="$t('title.Ticketno')">
        <el-input
          v-model="form.workNumber"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          @keyup.enter.native="_getAmazonPagedList(0)"
        />
      </el-form-item>
      <!-- 标签 -->
      <el-form-item v-if="showRow" :label="$t('title.ProblemMentioned')">
        <el-cascader
          v-model="form.labelsId"
          :options="tagData"
          :props=" { checkStrictly: true, value: 'id', label: language === 'en-US' ? 'labelNameEN' : 'labelName', children: 'childNodes' }"
          clearable
        >
          <template slot-scope="{ data }">
            <span v-if="language === 'en-US'">{{ data.labelNameEN }}</span> <span v-else>{{ data.labelName }}</span>
          </template>
        </el-cascader>
      </el-form-item>
      <!-- 定位 -->
      <el-form-item v-if="showRow" :label="$t('title.Productrank')">
        <el-select v-model="form.position" :placeholder="$t('page.selectPlaceholder')" clearable>
          <el-option label="P3" value="P3" />
          <el-option label="P2" value="P2" />
          <el-option label="P1" value="P1" />
          <el-option label="P0" value="P0" />
          <el-option :label="$t('title.NewProduct')" value="新品" />
        </el-select>
      </el-form-item>
      <!-- 关键字 -->
      <el-form-item v-if="showRow" :label="$t('title.keyword')">
        <el-input
          v-model="form.messageKey"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          @keyup.enter.native="_getAmazonPagedList(0)"
        />
      </el-form-item>
      <!-- 平台sku -->
      <el-form-item v-if="showRow" :label="$t('title.PlatformSku')">
        <el-input
          v-model="form.sku"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          @keyup.enter.native="_getAmazonPagedList(0)"
        />
      </el-form-item>
      <!--物流单号-->
      <el-form-item v-if="showRow" :label="$t('title.shipmentnumber')">
        <el-input
          v-model="form.trackingId"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          @keyup.enter.native="_getAmazonPagedList(0)"
        />
      </el-form-item>
      <!--处理人-->
      <el-form-item v-if="showRow" :label="$t('title.Agent')">
        <el-select v-model="form.lastReplyName" clearable filterable>
          <el-option
            v-for="item in userOptions"
            :key="item.id"
            :label="item.userName"
            :value="item.userName"
          />
        </el-select>
      </el-form-item>
      <!-- 时间类型 -->
      <el-form-item v-if="showRow" :label="$t('title.TimeType')">
        <el-select v-model="form.timeType" clearable filterable>
          <el-option :label="$t('title.FirstEmailTime')" :value="0" />
          <el-option :label="$t('title.LatestLetterTime')" :value="1" />
          <el-option :label="$t('title.LastReplyTime')" :value="2" />
          <el-option :label="$t('title.ClosedTime')" :value="3" />
        </el-select>
      </el-form-item>
      <!--时间-->
      <el-form-item v-if="showRow" :label="$t('title.time')">
        <el-date-picker
          v-model="form.timerange"
          :end-placeholder="$t('title.endtime')"
          :range-separator="$t('title.to')"
          :start-placeholder="$t('title.starttime')"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>
      <!--客户邮箱-->
      <el-form-item v-if="showRow" label="客户邮箱">
        <el-input
          v-model="form.messageSenderEmail"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          @keyup.enter.native="_getAmazonPagedList(0)"
        />
      </el-form-item>
      <!--手工发信结果-->
      <el-form-item v-if="showRow" :label="$t('page.manualSendingResults')">
        <el-select v-model="form.sendRegularlyStatusList" clearable filterable multiple>
          <el-option :value="0" label="等待发送" />
          <el-option :value="1" label="发送成功" />
          <el-option :value="2" label="取消发送" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button :loading="tableLoading" type="primary" @click="_getAmazonPagedList(0)"> {{ $t("page.search") }}</el-button>
        <el-button @click="resetQuery">{{ $t("page.reset") }}</el-button>
        <el-button type="text" @click="showRowClick()">{{ showRow ? $t("page.hide") : $t("page.expand") }}</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button size="small" type="primary" @click="PullmailbyhanddialogVisible = true"> {{ $t("title.Manualsync") }}</el-button>
        <el-button size="small" type="primary" @click="exportEmail"> {{ $t("page.export") }} </el-button>
        <el-button v-if="(form.messageStatus === '0' || form.messageStatus === '7') && form.workStatus === '0'" :disabled="!multipleSelection.length" size="small" type="primary" @click="batchTransferVisible = true">{{ $t("page.BatchTransfer") }}</el-button>
        <el-button size="small" type="primary" @click="$router.push({name: 'SendEmail'})">{{ $t('route.SendEmail') }}</el-button>
      </template>
    </vxe-toolbar>
    <table-select-action :quantity="multipleSelection.length" @reset="handleTableSelectReset" />
    <!-- tab切换 -->
    <el-tabs ref="tabs" v-model="form.messageStatus" @tab-click="handleTabsClick">
      <el-tab-pane v-for="item in StatusGroup" :key="item.messageStatus" :name="item.messageStatus + ''" lazy>
        <template #label>
          <span v-if="language === 'en-US'">{{ item.enremark }}</span> <span v-else>{{ item.messageStatusName }}</span>
          <span class="count" style="color: red">({{ item.messageCount }})</span>
        </template>
        <buyer-email-table
          ref="table"
          :disabled-flag="false"
          :flag="true"
          :form="form"
          :tab-name="item.messageStatus+''"
          :table-data="tableData"
          :table-height="tableHeight"
          :table-loading="tableLoading"
          @getMul="getMul"
          @getthreadList="getthreadList"
          @refresh="_getAmazonPagedList(0)"
        />
      </el-tab-pane>
    </el-tabs>
    <!-- 手工同步 -->
    <el-dialog :title="$t('title.Syncdate')" :visible.sync="PullmailbyhanddialogVisible" width="40%">
      <el-date-picker
        v-model="timeArr"
        :end-placeholder="$t('title.endtime')"
        :picker-options="setDisabled"
        :range-separator="$t('title.to')"
        :start-placeholder="$t('title.starttime')"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
      />
      <p style="color:red">点击同步确认按钮后:预计在五分钟后完成同步,在此期间基础功能不受影响,可关闭弹窗后继续操作</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="PullmailbyhanddialogVisible = false">{{ $t("title.cancel") }}</el-button>
        <el-button :loading="pullByHandLoading" type="primary" @click="PullmailbyhandSure">{{ $t("title.confirm") }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="batchTransferVisible"
      width="40%"
    >
      <span style="margin-right: 10px">{{ $t('title.Transferto') }}</span>
      <el-select v-model="batchTransfer" clearable filterable value-key="id">
        <el-option v-for="item in userOptions" :key="item.id" :label="item.userName" :value="item" />
      </el-select>
      <template #footer>
        <el-button @click="batchTransferVisible = false">{{ $t("title.cancel") }}</el-button>
        <el-button :loading="batchTransferLoading" type="primary" @click="handleBatchTransfer">{{ $t("page.sure") }}</el-button>
      </template>
    </el-dialog>
    <el-pagination
      ref="pager"
      :current-page="pager.current"
      :page-size="pager.size"
      :page-sizes="[20, 50, 100, 200, 300]"
      :total="pager.total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {
  allBU,
  batchTransfer,
  getAmazonMessageStatus,
  getAmazonPagedList,
  getEbayMessageByHand,
  getMailAccountAll,
  getmailormessageinfobyhand,
  querySiteList,
  selectMakeLabel
} from '@/api/service-management'
import { queryTerraceList } from '@/api/omsorder'
import BuyerEmailTable from './components/BuyerEmailTable'
import TableSelectAction from '@/components/TableSelectAction'
import { debounceGetTableMaxHeight } from '@/utils'
import Cookies from 'js-cookie'
import mixin from '@/mixin/oms-mixin'

export default {
  components: {
    TableSelectAction,
    BuyerEmailTable
  },
  mixins: [mixin],
  data() {
    return {
      batchTransfer: { },
      batchTransferLoading: false,
      language: '',
      pullByHandLoading: false,
      threadList: [],
      id: '',
      timeArr: [],
      batchTransferVisible: false,
      PullmailbyhanddialogVisible: false,
      StatusGroup: [],
      tableHeight: 0,
      tableLoading: true,
      tableData: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      showRow: false,
      terraceData: [],
      PlatformAccountList: [],
      form: {
        platform: '',
        account: '',
        site: '',
        bu: '',
        messageSender: '',
        workStatus: '0',
        orderCoder: '',
        workNumber: '',
        labelsId: '',
        position: '',
        messageKey: '',
        sku: '',
        trackingId: '',
        lastReplyName: '',
        timeType: 1,
        timerange: [],
        messageSenderEmail: '',
        sendRegularlyStatusList: [],
        messageType: '1',
        messageStatus: '0'
      },
      labelsId: '',
      multipleSelection: [],
      messageIDList: [],
      handleBatchreplyList: [],
      index: '',
      defaultProps: {
        children: 'childNodes',
        label: this.language === 'en-US' ? 'labelNameEN' : 'labelName'
      },
      tagData: [],
      buOptions: [],
      userOptions: [],
      isWarnLable: '',
      timer: null,
      platformList: [], // 平台条件查询
      setDisabled: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7 // 不可选历史天、可选当前天、可选未来天
        }
      },
      tabStatusMap: [
        { value: 0, enremark: 'Unanswered' },
        { value: 1, enremark: 'Answered' },
        { value: 3, enremark: 'Draft' },
        { value: 4, enremark: 'Failed to sent' },
        { value: 7, enremark: 'Execute no reply' },
        { value: 9, enremark: 'Manual work order' },
        { value: 99, enremark: 'Alert email' }
      ]
    }
  },
  computed: {
    getebaypagedlistParams() {
      this.getLabel()
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.form.timerange || []
      const { sendRegularlyStatusList, workStatus, workNumber, site, account, messageId, orderCoder, messageSender, bu, FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey, messageStatus, platform, messageSenderEmail, timeType } = this.form
      return Object.assign({}, { sendRegularlyStatusList, messageSenderEmail, workStatus, workNumber, platform, site, account, messageId, orderCoder, messageSender, bu: bu ? bu.join(',') : '', FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey, messageStatus, timeType }, this.pager, { lastModifyTimeStart, lastModifyTimeEnd, messageType: 1, isWarn: this.isWarnLable, labelsId: this.labelsId }
      )
    },
    getmessageallstatuscountbytypeParams() {
      const { sendRegularlyStatusList, workStatus, workNumber, site, account, platform, messageId, orderCoder, messageSender, bu, FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey, messageSenderEmail, timeType } = this.form
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.form.timerange || []
      return Object.assign({}, { sendRegularlyStatusList, messageSenderEmail, lastModifyTimeStart, lastModifyTimeEnd, platform }, { workStatus, workNumber, site, account, messageId, orderCoder, messageSender, bu: bu ? bu.join(',') : '', FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey, timeType }, { messageType: 1, isWarn: this.isWarnLable, labelsId: this.labelsId }
      )
    },
    exportUrl() {
      this.getLabel()
      const obj = {}
      const {
        sendRegularlyStatusList,
        workStatus, workNumber, isAttachment, platform, site, emailId, lastReplyName, trackingId, messageId, account, messageSender, orderCoder, bu, style, color, size, sku, position, messageKey, messageStatus, messageSenderEmail, timeType } = this.form
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.form.timerange || []
      const OBJ = Object.assign({}, { sendRegularlyStatusList, messageSenderEmail, workStatus, workNumber, isAttachment, platform, site, emailId, trackingId, lastReplyName, messageId, account, messageSender, orderCoder, bu: bu ? bu.join(',') : '', style, color, size, sku, position, messageKey, labelsId: this.labelsId, messageStatus, messageType: 1, timeType }, { isWarn: this.isWarnLable, lastModifyTimeStart, lastModifyTimeEnd })
      for (var key in OBJ) {
        OBJ[key] !== undefined && OBJ[key] !== null && OBJ[key] !== '' ? obj[key] = OBJ[key] : ''
      }
      let url = ''
      for (var i in obj) {
        url = `${i}=${obj[i]}` + '&' + url
      }
      url = url.substr(0, url.length - 1)
      return `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/exportMessageInfos?` + url
    }
  },
  created() {
    this.isFirst = true
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this, {
      maxHeightField: 'tableHeight'
    })
  },
  mounted() {
    // yyds-4019
    if (this.$route.query?.orderCoder) {
      this.form.orderCoder = this.$route.query?.orderCoder
    }

    window.addEventListener('resize', this.debounceGetTableMaxHeight)
    const data = JSON.parse(sessionStorage.getItem('queryForm'))
    this.form = data || this.form
    this.form.timerange = data ? data.timerange : this.form.timerange
    this.language = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    this._selectMakeLabel()
    this._querySiteList()
    this._allBU(true)
    this._queryTerraceList()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async handleBatchTransfer() {
      if (!this.batchTransfer.id) {
        this.$message.error('请选择需要流转的人员')
        return
      }
      const list = this.multipleSelection.map(item => {
        return {
          id: item.id,
          messageId: item.id,
          workNumber: item.workNumber,
          workFrom: this.$store.getters.name,
          workFromId: this.$store.getters.userId,
          workTo: this.batchTransfer.userName,
          workToId: this.batchTransfer.id
        }
      })
      this.batchTransferLoading = true
      const { code, msg } = await batchTransfer(list).finally(() => {
        this.batchTransferLoading = false
      })
      if (code === 0) {
        this.$message.success(msg)
        await this._getAmazonPagedList(0)
        this.batchTransferVisible = false
      }
    },
    // 获取平台
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platformList = datas
      this.platformList = this.platformList.filter((item) => {
        return item.platformCode === 'amazon' ||
        item.platformCode === 'ebay' ||
        item.platformCode === 'walmart' ||
        item.platformCode === 'Shopify' ||
        item.platformCode === 'standwebsite' ||
        // sb正式测试不一样
        item.platformCode === 'Tik Tok' ||
        item.platformCode === 'TikTok'
      })
    },
    // 获取所有bu
    async _allBU(isMounted) {
      const { datas } = await allBU()
      // 是否首次加载
      if (isMounted && sessionStorage.getItem('queryForm') === null) {
        let tempBu = []
        datas.map(e => {
          if (e.userName === this.$store.getters.name && sessionStorage.getItem('queryForm') === null) {
            // yyds-4019如果是从详情里面跳转出来的,就不默认赋值
            if (!this.form?.orderCoder) {
              this.form.lastReplyName = e.userName
            }
          }
          if (e.userName === this.$store.getters.name && sessionStorage.getItem('queryForm') === null) {
            // yyds-4019如果是从详情里面跳转出来的,就不默认赋值
            if (!this.form?.orderCoder) {
              tempBu = tempBu.concat(e.bu?.split(',') || [])
            }
          }
        })
        this.form.bu = tempBu.length ? tempBu : []
      }
      this.userOptions = datas
      this.buOptions = []
      datas.map(e => {
        const index = this.buOptions.findIndex((value) => value.bu === e.bu)
        // 有两个没有bu字段的数据
        index === -1 && e.bu ? this.buOptions.push(e) : ''
      })
      // 还得手动加上bu为空的字段查询
      this.buOptions.push({ id: 'empty', bu: '空' })
      sessionStorage.removeItem('queryForm')
      this._getAmazonPagedList(0)
    },
    // closeTag() {
    //   this.show = 'button'
    // },
    getLabel() {
      this.labelsId = this.form.labelsId
      this.labelsId = this.labelsId && this.labelsId[this.labelsId.length - 1]
    },
    async _selectMakeLabel() {
      const form = this.form.platform ? this.form.platform : '-1'
      const { datas } = await selectMakeLabel(form, 1)
      this.tagData = datas
    },
    getthreadList(val) {
      this.threadList = val
    },
    // 导出
    exportEmail() {
      window.open(this.exportUrl, '_blank')
    },
    async _getmailallstatuscountbytype() {
      const { datas } = await getAmazonMessageStatus(
        this.getmessageallstatuscountbytypeParams
      )
      for (var i = 0; i < datas.length; i++) {
        datas[i].enremark = this.tabStatusMap.find((item) => item.value === datas[i].messageStatus)?.enremark
      }
      this.StatusGroup = datas
    },
    // 重置条件查询
    resetQuery() {
      this.form = this.$options.data().form
      this.form.site = []
      this.form.timerange = []
      this.timeArr = []
      this.isWarnLable = ''
      this.workStatusChange()
    },
    // 手工同步确认提交数据
    async PullmailbyhandSure() {
      try {
        this.pullByHandLoading = true
        const [startTime, endTime] = this.timeArr || []
        const { account } = this.form
        const { code } = await getEbayMessageByHand('US', account.toString() ? account : '-1', startTime, endTime)

        const { status } = await getmailormessageinfobyhand(startTime, endTime)
        if (status === 0 && code === 0) {
          this.$message.success(this.$t('tips.EmailRetrieved'))
        } else if (status !== 0 || code !== 0) {
          this.$message.error(this.$t('tips.Failed') + '请联系IT相关技术人员')
        } else {
          this.$message.error(this.$t('tips.Failed') + '请联系IT相关技术人员')
        }
        this.pullByHandLoading = false
        this.PullmailbyhanddialogVisible = false
      } catch (err) {
        console.log(err)
        this.$message.error(this.$t('tips.Failed') + '请联系IT相关技术人员')
      } finally {
        this.pullByHandLoading = false
      }
    },

    // 列表数据默认查询
    async _getAmazonPagedList(type) {
      type === 0 ? this.pager.current = 1 : ''
      this.tableLoading = true
      const { datas: { pager, records }} = await getAmazonPagedList(this.getebaypagedlistParams).finally(() => {
        this.tableLoading = false
      })
      this.tableData = (records || []).map((e, i) => ({
        ...e,
        index: i
      }))
      this.pager = pager
      await Promise.all([this._getmailallstatuscountbytype(), this._selectMakeLabel()])
      if (this.isFirst) {
        this.isFirst = false
        await this.$nextTick()
        this.debounceGetTableMaxHeight()
      }
    },

    //  列表分页方法
    handleSizeChange(val) {
      this.pager.size = val
      this._getAmazonPagedList(0)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getAmazonPagedList(1)
    },
    // 子传父
    getMul(data) {
      this.multipleSelection = data
      this.messageIDList = this.multipleSelection.map((item) => item.messageSender)
      this.handleBatchreplyList = this.multipleSelection.map(
        (item) => item.messageSender
      )
    },
    // 工单状态 已完结未完结  change事件
    workStatusChange() {
      if (this.form.workStatus === '99') {
        this.form.timeType = 3
      } else {
        this.form.timeType = 0
      }
      this._getAmazonPagedList(0)
      if (this.form.workStatus === '99') {
        this.$nextTick(function() {
          this.$refs.tabs.$children[0].$refs.tabs.map(e => {
            e.style.display = 'none'
          })
          this.form.messageStatus = '0'
        })
      } else {
        this.$nextTick(function() {
          this.$refs.tabs.$children[0].$refs.tabs.map(e => {
            e.style.display = 'inline-block'
          })
        })
      }
    },
    // 查询条件展开隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 账号change事件
    PlatformAccountChange(val) {
      this.form.account = val
      const value = this.PlatformAccountList.find(e => e.account === val)
      this.terraceData = value.siteList
      this.form.site = ''
    },
    async getMailAccountAll(platform) {
      const { datas } = await getMailAccountAll(platform)
      datas.length
        ? (this.terraceData = datas[0].siteList)
        : (this.terraceData = [])
      this.PlatformAccountList = datas
    },
    // 获取站点
    async _querySiteList() {
      const { datas } = await querySiteList({ sellPlatformId: 8 })
      this.terraceData = datas
    },
    // 平台change事件
    platformChange() {
      this.getMailAccountAll(this.form.platform ? this.form.platform : '-1')
      this.form.account = ''
      this.form.site = ''
      this._selectMakeLabel()
    },
    // table栏切换
    handleTableSelectReset() {
      this.multipleSelection = []
      for (let i = 0; i < this.$refs.table.length; i++) {
        this.$refs.table[i].$refs.table.clearSelection()
      }
    },
    handleTabsClick(tab, event) {
      this.isWarnLable = tab.name === '99' ? tab.name : ''
      this._getAmazonPagedList(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__body {
    padding: 15px 10px;
    border: 1px solid #eee;
    margin: 5px 5px;
}
.browBox {
  position: absolute;
  bottom: -150px;
  width: 100%;
  height: 150px;
  background: #e6e6e6;
  overflow: scroll;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
    li {
      width: 10%;
      font-size: 20px;
      list-style: none;
      text-align: center;
    }
  }
}
.tableTab {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #f4f4f5;
  span {
    padding: 0 50px;
  }
}
/deep/.el-textarea__inner {
  height: 150px;
  overflow: auto;
}
.faceicon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
}
/deep/.el-date-editor {
  width: 100%;
  .el-range-separator {
    padding: 0;
  }
}
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 110px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
.addWidth{
  width: 100%;
}
</style>
