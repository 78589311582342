<template>
  <div>
    <el-table
      ref="table"
      v-loading="tableLoading"
      :cell-style="({row,column}) => +row.sendRegularlyStatus === 2 && column.property==='sendRegularlyStatusName' ? {color: 'red'} : ''"
      :data="tableData"
      :header-cell-style="{ background: '#fafafa' }"
      :max-height="tableHeight"
      :row-class-name="tableRowClassName"
      border
      class="mb-3"
      style="width: 100%"
      tooltip-effect="dark"
      @row-click="nextshowdialogVisible"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" type="selection" width="55" />
      <el-table-column :label="$t('title.Ticketno')" align="center" prop="workNumber" width="200">
        <template slot-scope="scope">
          <el-popover :content="scope.row.workNumber" placement="top-start" trigger="hover">
            <span
              slot="reference"
              @dblclick="copy(scope.row.workNumber)"
            >{{ scope.row.workNumber }}</span>
          </el-popover>
          <span v-if="scope.row.checked" class="ticketno-checked">Checked</span>
          <!-- <span @dblclick="copy(scope.row.workNumber)">{{ scope.row.workNumber }}</span> -->
          <span v-if="scope.row.platform ==='AMAZON' ">
            <el-tooltip v-if="scope.row.atoZStatus ===6 " class="item" content="Granted" placement="top">
              <el-button plain size="mini" style="position: absolute; z-index: 99999;top:28px" type="primary">A-to-Z</el-button>
            </el-tooltip>
            <el-tooltip v-if="scope.row.atoZStatus ===5 " class="item" content="Closed" placement="top">
              <el-button plain size="mini" style="position: absolute; z-index: 99999;top:28px" type="success">A-to-Z</el-button>
            </el-tooltip>
            <el-tooltip v-if="scope.row.atoZStatus === 4" class="item" content="Under Review" placement="top">
              <el-button plain size="mini" style="position: absolute; z-index: 99999;top:28px" type="warning">A-to-Z</el-button>
            </el-tooltip>
            <el-tooltip v-if="scope.row.atoZStatus === 3" class="item" content="Action Required" placement="top">
              <el-button plain size="mini" style="position: absolute; z-index: 99999;top:28px" type="danger">A-to-Z</el-button>
            </el-tooltip>
            <el-tooltip v-if="scope.row.atoZStatus === 7" class="item" content="UnKnow" placement="top">
              <el-button plain size="mini" style="position: absolute; z-index: 99999;top:28px">A-to-Z</el-button>
            </el-tooltip></span>
        </template>
      </el-table-column>
      <!-- 顾客ID -->

      <el-table-column :label="$t('title.customerID')" align="center" width="175">
        <template slot-scope="scope">
          <el-popover :content="scope.row.messageSender" placement="top-start" trigger="hover"><span
            slot="reference"
            @dblclick="copy(scope.row.messageSender)"
          >{{ scope.row.messageSender }}</span></el-popover>
        </template>
      </el-table-column>
      <!-- 最新消息 -->
      <el-table-column :label="$t('title.Subjectmail')" align="center" min-width="230px">
        <template slot-scope="scope">
          <i v-if=" !disabledFlag && scope.row.isWarn === 1 && Date.parse(new Date()) > Date.parse(new Date(scope.row.warnTime)) && scope.row.messageStatus === 1 " class="el-icon-alarm-clock" style="cursor: pointer; margin-right: 2px" @click="cancelIsWarn(scope.row.id, 0, setDate(0))" />
          <i v-if=" disabledFlag && scope.row.isWarn === 1 && Date.parse(new Date()) > Date.parse(new Date(scope.row.warnTime)) && scope.row.messageStatus === 5 " class="el-icon-alarm-clock" style="cursor: pointer; margin-right: 2px" @click="cancelIsWarn(scope.row.id, 0, setDate(0))" />
          <el-button :class="scope.row.isRead === 0 ? 'unread' : 'read'" :style="scope.row.warnTime? 'color: red': ''" type="text">{{ scope.row.messageText | ellipsis }}</el-button>
          <br>
        </template>
      </el-table-column>
      <!-- 工单状态 -->
      <el-table-column
        v-if="+form.messageStatus === 9"
        key="sendRegularlyStatusName"
        align="center"
        label="手工发信结果"
        prop="sendRegularlyStatusName"
        width="140"
      />
      <!-- 工单状态 -->
      <el-table-column v-else key="messageId" :label="$t('title.TicketStatus')" align="center" prop="messageId" width="140">
        <template slot-scope="scope">
          <div v-if="scope.row.workStatus == 99 || scope.row.messageStatus !==0 ">
            <p v-if="scope.row.workStatus == 0"> {{ $t("title.Unfinished") }}</p>
            <p v-if="scope.row.workStatus == 99">  {{ $t("title.finished") }}</p>
          </div>
          <el-badge v-else :value="scope.row.endTime" class="item">
            <p v-if="scope.row.workStatus == 0"> {{ $t("title.Unfinished") }}</p>
            <p v-if="scope.row.workStatus == 99">  {{ $t("title.finished") }}</p>
          </el-badge>
        </template>
      </el-table-column>
      <!-- 产品信息 -->
      <el-table-column :label="$t('title.Productinformation')" align="center" width="180">
        <template slot-scope="scope">
          <div> BU：<span v-for="(item,index) in scope.row.bu" :key="index">{{ item }}</span> </div>
        </template>
      </el-table-column>
      <!-- 处理人 -->
      <el-table-column :label="$t('title.Agent')" align="center" prop="operatorName" width="120" />
      <!-- 工单处理时间 -->
      <el-table-column
        v-if="+form.messageStatus === 9 || +form.messageStatus === 1 || +form.messageStatus === 4"
        key="lastReplyTime"
        align="center"
        :label="$t('title.LastReplyTime')"
        prop="lastReplyTime"
        width="160"
      />
      <!-- 工单处理时间 -->
      <el-table-column v-else key="customerLastTime" :label="$t('title.LatestLetterTime')" align="center" prop="customerLastTime" width="160" />
    </el-table>
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import mixin from '@/mixin/oms-mixin'

export default {
  name: 'BuyerTable',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 38) {
        // 判断字段
        return value.slice(0, 38) + '...'
      }
      return value
    }
  },
  mixins: [mixin],
  props: {
    disabledFlag: {
      type: Boolean,
      default: false
    },
    newsStyle: {
      type: Object,
      default: function() {
        return {
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
          width: '240px'
        }
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: [String, Number],
      default: ''
    },
    tableData: {
      type: Array,
      default: () => []
    },
    form: {
      type: Object,
      default: () => ({})
    },
    tabName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      noteLists: [],
      ids: [],
      sendLoading: false,
      faceList: [],
      OrderInfo: [],
      faceShow: false,
      basicInfo: {},
      dialogVisible: false,
      multipleSelection: [],
      checked: false,
      content: '',
      customerMessageList: [],
      threadListMarkasreplied: [],
      historyData: [],
      templateData: [],
      contentData: [],
      inputContent: '',
      templateType: '0',
      action: process.env.VUE_APP_UPLOAD_API,
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      imageUrl: '',
      temp: null, // 倒计时初始
      changeIndex: ''
      // tableData: []
    }
  },
  // watch: {
  //   dialogVisible(val) {
  //     if (!val) {
  //       this.faceShow = false
  //       this.checked = false
  //       this.$refs.orderinfo.noteList = []
  //       this.basicInfo.messageMarkLabelList &&
  //       this.basicInfo.messageMarkLabelList.length
  //         ? (this.basicInfo.messageMarkLabelList[0].markLabel = '')
  //         : ''
  //       this.OrderInfo ? (this.OrderInfo = []) : ''
  //     }
  //   }
  // },
  mounted() {
    this.timer()
  },
  destroyed() {
    // 切记页面销毁需要销毁
    clearInterval(this.temp)
  },
  methods: {
    // 处理倒计时
    timer() {
      // 页面多个定时器 //主要逻辑都在这页面更新
      this.temp = setInterval(() => {
        this.tableData.forEach((item, index) => {
          // let startTime = new Date(item.createTime).getTime();
          const endTime =
            new Date(item.noReplyTime).getTime() + 24 * 60 * 60 * 1000
          item.endTime = this.countDownFun(endTime)
          this.$set(this.tableData, item.endTime, this.countDownFun(endTime))
          // this.$set(this.tableData,"endTime", item.endTime)
        })
      }, 1000)
    },
    // 倒计时
    countDownFun(time) {
      time-- // 时间一秒秒的减
      const nowTime = new Date().getTime() // 现在时间
      if (nowTime <= time) {
        // 获取时间差
        const timediff = Math.round((time - nowTime) / 1000)
        // 获取还剩多少天
        const day = parseInt(timediff / 3600 / 24)
        // 获取还剩多少小时
        const hour = parseInt((timediff / 3600) % 24)
        // 获取还剩多少分钟
        const minute = parseInt((timediff / 60) % 60)
        // 获取还剩多少秒
        const second = timediff % 60
        // return day + "天" + hour + "小时" + minute + "分" + second + "秒";
        // return second+"s"
        if (day !== 0) {
          return day + 'day'
        } else if (hour !== 0) {
          return hour + 'h'
        } else if (minute !== 0) {
          return minute + 'min'
        } else if (second !== 0) {
          return second + 's'
        }
      } else {
        // return "00天00小时00分00秒";
        return '超'
      }
    },
    // 复制
    copy(value) {
      var input = document.createElement('input')
      input.value = value
      document.body.appendChild(input)
      input.select()
      document.body.removeChild(input)
      this.$message.success('复制成功')
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      const ids = deepClone(val)
      this.ids = ids.map((item) => item.id)
      this.$emit('getthreadList', this.ids)
      this.$emit('getMul', this.multipleSelection)
    },
    // getRowStyle({ row }) {
    //   const stylejson = {}
    //   if (row.isRead === 1) {
    //     stylejson.color = '#999'
    //     return stylejson
    //   } else {
    //     return ''
    //   }
    // },
    // 标红当前行
    tableRowClassName({ row }) {
      if (
        (row.platform === 'AMAZON' && row.site === 'com' && (row?.messageText?.includes('Order cancellation request from Amazon customer') || row?.messageText?.includes('Return policy inquiry from Amazon customer') || row?.messageText?.includes('Order cancelation request for Order ID'))) ||
        (row.platform === 'AMAZON' && row.site === 'ca' && (row?.messageText?.includes('Order cancellation request from Amazon customer') || row?.messageText?.includes('Return policy inquiry from Amazon customer') || row?.messageText?.includes('Return Requested for order') || row?.messageText?.includes('Order cancelation request for Order ID'))) ||
        (row.platform === 'AMAZON' && row.site === 'uk' && (row?.messageText?.includes('Order cancellation request from Amazon customer') || row?.messageText?.includes('Return policy enquiry from Amazon customer')) || row?.messageText?.includes('Order cancelation request for Order ID'))
      ) {
        return 'warning-row'
      } else {
        return ''
      }
    },
    nextshowdialogVisible(row) {
      sessionStorage.setItem('queryForm', JSON.stringify(this.form))
      const routeUrl = this.$router.resolve({
        path: '../message-reply',
        append: true,
        query: { 'id': row.id, 'form': JSON.stringify(this.form), ...this.userInfo }
      })
      window.open(routeUrl.href, '_blank')
      // this.$router.push({
      //   path: '../message-reply',
      //   append: true,
      //   query: { 'id': row.id, 'form': JSON.stringify(this.form) }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-badge__content.is-fixed {
  position: absolute;
  top: 8px;
  right: 5px;
  -webkit-transform: translateY(-50%) translateX(100%);
  transform: translateY(-50%) translateX(100%);
}
.browBox {
  width: 100%;
  height: 130px;
  background: #e6e6e6;
  position: absolute;
  top: 29px;
  overflow: scroll;
  z-index: 999;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
    li {
      width: 7%;
      font-size: 20px;
      list-style: none;
      text-align: center;
    }
  }
}
.dialog {
  em {
    font-style: normal;
    margin-right: 40px;
  }
  .border {
    border-bottom: 1px solid #ccc;
  }
}

/deep/.cell {
  min-height: 40px !important;
  line-height: 40px !important;
  // position: relative !important;
  em {
    position: absolute;
    top: 0px;
    right: 10px;
    padding: 0 10px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: white;
    background-color: red;
    border-radius: 10px;
    font-style: normal;
  }
}
/deep/.el-table .warning-row{
  color:red;
}
.wrap {
  width: 100%;
  border: 1px solid #ccc;
  ul {
    min-height: 40px;
    list-style: none;
    li {
      overflow: hidden;
      margin: 10px 0px 10px;
    }
    .left_word {
      div {
        float: left;
        background-color: rgb(255, 255, 255);
        padding: 5px 10px;
        max-width: 60%;
        overflow-x: auto !important;
        border: 1px solid #ccc;
        border-radius: 12px;
        font-size: 14px;
        color: #000;
        margin-left: 13px;
        position: relative;
        line-height: 24px;
        /deep/.el-image {
          border: none !important;
          width: 70px !important;
          height: 70px !important;
          margin-left: 0 !important;
          padding: 5px !important;
        }
      }
    }
    .Left_Word {
      span {
        float: left;
        background-color: rgb(255, 255, 255);
        padding: 5px 10px;
        max-width: 95%;
        overflow-x: auto !important;
        border: 1px solid #ccc;
        border-radius: 12px;
        font-size: 14px;
        color: #000;
        margin-left: 13px;
        position: relative;
        line-height: 24px;
      }
    }
    .right_word {
      span {
        float: right;
        background-color: #00cc00;
        padding: 5px 10px;
        max-width: 70%;
        border-radius: 12px;
        font-size: 14px;
        color: #fff;
        margin-right: 13px;
        position: relative;
        line-height: 24px;
      }
    }
  }
  .main {
    height: 360px;
    overflow: auto;
    .talk_list {
      padding: 0px !important;
    }
  }
  .footer {
    position: relative;
    margin: 10px 5px;
  }
}
/deep/ .el-textarea__inner {
  min-height: 85px !important;
  line-height: 1 !important;
}
.unread {
  color: #333;
}
.read {
  color: #999;
}
.app-container {
  padding: 10px;
}
.el-table {
  /deep/ th {
    padding: 0;
  }
}

/deep/.el-table thead .cell {
  padding-top: 10px;
  line-height: 30px;
}
/deep/.el-icon-circle-close:before {
  content: "\e78d";
  color: #fff !important;
}
.active {
  color: rgb(218, 218, 218);
}

.ticketno-checked{
  position: absolute;
  top: 15px;
  right: -5px;
  color: #fff;
  background-color: #ff4949;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  padding: 0px 5px;
  transform: scale(0.8);
}
</style>
